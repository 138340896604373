.accountbg{
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  background-size: cover;
}

.login-center {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-wrappere {
  width: 420px;
  position: relative;
  margin: auto;
  background: #fff;
  padding: 25px;
  border-radius: 17px;
}

.log_cap{
  text-align: center;
  font-size: 18px;
  margin-bottom: 35px;
}

.login-form-button{
  background: var(--theme-primary-background);
  color: var(--font-white);
}

.login-form-forgot{
  color: #000;
}